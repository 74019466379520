import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { Link, useHistory } from '@deal/router'
import { Area, BrandLogo, Button, Grid, Heading, Text } from '@deal/bluxome'
import useHomepageLocation from '#src/app/hooks/useHomepageLocation'
import { useIdentityContext } from '#src/app/containers/Identity'
import { CategoryForScoutHeroSectionFragment } from './ScoutHeroSection.generated'
import { ScoutStartChattingButton } from '../ScoutStartChattingButton'
import SignInIcon from './sign-in.svg'
import { WinterSportsHeroVideo } from './ScoutHeroVideo'
import { GolfHeroImages } from './ScoutHeroImages'
import styles from './styles.css'

interface ScoutHeroSectionPropsWithoutChildren extends ScoutHeroSectionContentProps {
  category?: CategoryForScoutHeroSectionFragment | null
  className?: string
  children?: never
}

type ScoutHeroSectionPropsWithChildren = {
  [K in keyof Omit<ScoutHeroSectionPropsWithoutChildren, 'children'>]?: never
} & { children: React.ReactNode }

// Polymorphism allows this to be called by both the `loadingComponent` & `pageComponent` props of the top-level <Page>
type ScoutHeroSectionProps =
  | ScoutHeroSectionPropsWithoutChildren
  | ScoutHeroSectionPropsWithChildren

export const ScoutHeroSection: React.FC<ScoutHeroSectionProps> = ({
  category,
  onMainSectionAnchorClicked,
  className,
  children,
  ...props
}) => {
  const heroRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [isSecondaryHeaderVisible, setIsSecondaryHeaderVisible] = useState(false)
  useEffect(() => {
    const heroElement = heroRef.current
    if (!category || !heroElement) {
      return
    }
    let didScroll = false
    const handleScroll = () => {
      didScroll = true
    }
    // Avoid doing any calculations in the scroll event handler, just set a flag
    document.body.addEventListener('scroll', handleScroll, { passive: true })
    // Every 250ms, check if the container was scrolled
    // If the scroll depth exceeds the hero element's height, reveal the secondary header element
    const interval = setInterval(() => {
      if (didScroll) {
        didScroll = false
        setIsSecondaryHeaderVisible(document.body.scrollTop >= heroElement.clientHeight)
      }
    }, 250)
    return () => {
      clearInterval(interval)
      document.body.removeEventListener('scroll', handleScroll)
    }
  }, [category])

  return (
    <>
      <div className={classNames(styles.section, className)} ref={heroRef} {...props}>
        <Header category={category} className={styles.primaryHeader} />
        {category ? (
          <>
            <ScoutHeroSectionContent
              ref={buttonRef}
              category={category}
              onMainSectionAnchorClicked={onMainSectionAnchorClicked}
            />
            {category.slug === 'golf' ? (
              <GolfHeroImages />
            ) : (
              <WinterSportsHeroVideo onClickInsideBoundary={() => buttonRef.current?.click()} />
            )}
          </>
        ) : (
          <>{children}</>
        )}
      </div>
      {category ? (
        <Header
          category={category}
          className={classNames(styles.secondaryHeader, {
            [styles.visible]: isSecondaryHeaderVisible
          })}
        />
      ) : null}
    </>
  )
}

interface HeaderProps {
  category?: CategoryForScoutHeroSectionFragment | null
  className?: string
}

const Header: React.FC<HeaderProps> = ({ category, className }) => {
  const { myself, incompleteUser } = useIdentityContext()
  const homepageLocation = useHomepageLocation()
  return (
    <header className={className}>
      <Grid>
        <Area column="span 3" className="flex items-center">
          <Link to={homepageLocation} aria-label="Home">
            <BrandLogo color="black" />
          </Link>
        </Area>
        <Area column="span 3" className="flex items-center justify-end gap-2.5">
          <div className={classNames({ invisible: myself && !incompleteUser })}>
            <Button
              color="neutral-dark"
              style="text"
              size="small"
              icon={SignInIcon}
              link={{ to: '/auth/login' }}
            >
              Sign in
            </Button>
          </div>
          <div className={styles.getStarted}>
            <ScoutStartChattingButton
              category={category}
              color="neutral-dark"
              size="small"
              icon={null}
              label="Get started"
            />
          </div>
        </Area>
      </Grid>
    </header>
  )
}

interface ScoutHeroSectionContentProps extends React.PropsWithChildren {
  category?: CategoryForScoutHeroSectionFragment | null
  onMainSectionAnchorClicked: () => void
}

const ScoutHeroSectionContent = React.forwardRef<HTMLButtonElement, ScoutHeroSectionContentProps>(
  ({ category, onMainSectionAnchorClicked, children }, ref) => {
    const history = useHistory()
    return (
      <div className={styles.grid}>
        <Grid>
          <Area column="span 6">
            <div className={styles.content}>
              <Heading as="h1" size="huge">
                A new way
                <br />
                of shopping
              </Heading>
              <Text style="large-loose" as="p">
                Meet scout — your personal shopping assistant that helps you buy the right products
                with knowledge from former pros, verified specialists, and gear geeks.
              </Text>
            </div>
          </Area>
          <Area column="span 6">
            <div className={styles.buttons}>
              <ScoutStartChattingButton ref={ref} category={category} />
              <Button
                color="neutral-dark"
                style="text"
                size="base"
                link={{
                  to: '#how-it-works',
                  onClick: e => {
                    if (onMainSectionAnchorClicked) {
                      e.preventDefault()
                      history.push('#how-it-works')
                      onMainSectionAnchorClicked()
                    }
                  }
                }}
              >
                How it works
              </Button>
            </div>
          </Area>
          {children}
        </Grid>
      </div>
    )
  }
)
ScoutHeroSectionContent.displayName = 'ScoutHeroSectionContent'
