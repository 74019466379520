import React from 'react'
import loadable from '@loadable/component'
import { ScoutLoading } from '../loading'

export const ScoutLandingPageComponent = loadable(
  () => import(/* webpackChunkName: "scout" */ './ScoutLandingPage'),
  {
    resolveComponent: module => module.ScoutLandingPage,
    fallback: <ScoutLoading />
  }
)

export const ScoutInboxPageComponent = loadable(
  () => import(/* webpackChunkName: "scout-inbox" */ './ScoutInboxPage'),
  {
    resolveComponent: module => module.ScoutInboxPage,
    fallback: <ScoutLoading />
  }
)
