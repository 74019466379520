import React from 'react'
import { toast } from 'react-toastify'
import { useHistory } from '@deal/router'
import { Button, ButtonProps } from '@deal/bluxome'
import { useCurrentExpert } from '#src/app/context/expert'
import { useIdentityContext } from '#src/app/containers/Identity'
import config from '#src/app/config'
import {
  CategoryForScoutStartChattingButtonFragment,
  useStartAiGuestConversationForScoutStartChattingButtonMutation
} from './ScoutStartChattingButton.generated'
import { ScoutInboxPageComponent } from '../../page'
import ArrowIcon from './arrow.svg'

const scoutDepartmentId = config.get('scout.departmentId') || ''

interface ScoutStartChattingButtonProps
  extends Partial<Pick<ButtonProps, 'size' | 'color' | 'style' | 'fullWidth'>> {
  label?: string
  icon?: ButtonProps['icon'] | null
  category?: CategoryForScoutStartChattingButtonFragment | null
}

export const ScoutStartChattingButton = React.forwardRef<
  HTMLButtonElement,
  ScoutStartChattingButtonProps
>(
  (
    {
      label = 'Start chatting',
      size = 'base',
      color = 'learn-blue',
      style = 'filled',
      icon = ArrowIcon,
      category,
      ...buttonProps
    },
    ref
  ) => {
    const history = useHistory()
    const { refetch: refetchIdentity = () => Promise.resolve() } = useIdentityContext()
    const { setExpert } = useCurrentExpert()
    const [startAiGuestConversation, { loading }] =
      useStartAiGuestConversationForScoutStartChattingButtonMutation({
        onCompleted: ({ startAiGuestConversation: { lead, myself, error } }) => {
          const { conversation } = lead || {}
          const { expert } = conversation?.lead || {}
          if (!error && myself && conversation) {
            refetchIdentity().then(() => {
              setExpert(expert)
              history.push(`/scout/inbox/${conversation.externalId}`)
            })
          } else if (error) {
            toast.error(error)
          }
        },
        onError: error => {
          toast.error(error.message)
        }
      })

    const departmentId = category?.department?.id || scoutDepartmentId

    if (!departmentId) {
      return null
    }

    return (
      <div
        onPointerEnter={() => {
          // The mutation bound to onPress will navigate to the route where this component is used
          // Preloading creates a smoother experience
          ScoutInboxPageComponent.preload()
        }}
        className="contents"
      >
        <Button
          ref={ref}
          size={size}
          color={color}
          style={style}
          onPress={() => {
            startAiGuestConversation({ variables: { departmentId } })
          }}
          isLoading={loading}
          icon={icon === null ? undefined : icon}
          iconPlacement="end"
          {...buttonProps}
        >
          {label}
        </Button>
      </div>
    )
  }
)

ScoutStartChattingButton.displayName = 'ScoutStartChattingButton'
