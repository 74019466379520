import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'
import clientOnly from '#src/app/hocs/clientOnly'
import styles from './styles.css'

interface CanvasVideoProps extends React.VideoHTMLAttributes<HTMLVideoElement> {}

const CanvasVideo: React.FC<CanvasVideoProps> = ({ className, ...rest }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const animationFrameHandleRef = useRef<number>()

  useEffect(() => {
    const animationFrameHandle = animationFrameHandleRef.current
    if (animationFrameHandle) {
      return () => {
        window.cancelAnimationFrame(animationFrameHandle)
      }
    }
  }, [])

  return (
    <div className={classNames(styles.videoContainer, className)}>
      <video
        {...rest}
        preload="metadata"
        onLoadedMetadata={e => {
          const video = e.target as HTMLVideoElement
          const canvas = canvasRef.current
          if (video && canvas) {
            canvas.width = video.videoWidth
            canvas.height = video.videoHeight
          }
        }}
        onPlayCapture={e => {
          const video = e.target as HTMLVideoElement
          const canvas = canvasRef.current
          const ctx = canvas?.getContext('2d')
          if (video && canvas && ctx) {
            const drawFrame = () => {
              ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
              animationFrameHandleRef.current = requestAnimationFrame(drawFrame)
            }
            animationFrameHandleRef.current = requestAnimationFrame(drawFrame)
          }
        }}
        onPlay={e => {
          const video = e.target as HTMLVideoElement
          if (video && !video.hidden) {
            video.hidden = true
            // Need to call .play() again because changing the hidden attr pauses
            animationFrameHandleRef.current = requestAnimationFrame(() => video.play())
          }
        }}
        onPause={() => {
          const animationFrameHandle = animationFrameHandleRef.current
          if (animationFrameHandle) {
            window.cancelAnimationFrame(animationFrameHandle)
          }
        }}
      />
      <canvas ref={canvasRef} className={styles.canvas} />
    </div>
  )
}

const CanvasVideoClientOnly = clientOnly(CanvasVideo, {
  fallback: ({ autoPlay, className, ...rest }) => (
    <div className={classNames(styles.videoContainer, className)}>
      <video {...rest} preload="metadata" />
    </div>
  )
})

export { CanvasVideoClientOnly as CanvasVideo }
