import React from 'react'
import { CanvasVideo } from '#src/app/components/CanvasVideo'
import WSLPVideo from './ws-lp.mp4'
import styles from './styles.css'

interface WinterSportsHeroVideoProps {
  onClickInsideBoundary: () => void
}

export const WinterSportsHeroVideo: React.FC<WinterSportsHeroVideoProps> = ({
  onClickInsideBoundary
}) => {
  return (
    <div
      className="contents"
      onClick={e => {
        if (!(e.target instanceof HTMLElement)) {
          return
        }
        const rect = e.target.getBoundingClientRect()
        const clickX = (e.clientX - rect.left) / rect.width
        const clickY = (e.clientY - rect.top) / rect.height
        if (
          isBetween(clickX, VIDEO_CLICKABLE_BOUNDARIES.x) &&
          isBetween(clickY, VIDEO_CLICKABLE_BOUNDARIES.y)
        ) {
          onClickInsideBoundary()
        }
      }}
    >
      <CanvasVideo className={styles.heroVideo} src={WSLPVideo} autoPlay loop muted playsInline />
    </div>
  )
}

// The video shows a product demo over a background image. Clicking on the simulated phone screen,
// which occupies a portion of the video dimensions, should open the scout modal
const VIDEO_CLICKABLE_BOUNDARIES = {
  x: { min: 0.23, max: 0.77 },
  y: { min: 0.08, max: 0.92 }
}

function isBetween(value: number, bounds: { min: number; max: number }) {
  return value > bounds.min && value < bounds.max
}
