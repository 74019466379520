import React from 'react'
import { CircleLoader } from '@deal/components'
import { ScoutHeroSection } from '../components/ScoutHeroSection'

interface ScoutLoadingProps {
  /** @default true */
  isLoading?: boolean
}

export const ScoutLoading: React.FC<ScoutLoadingProps> = ({ isLoading = true }) => {
  return (
    <ScoutHeroSection>
      {isLoading ? <CircleLoader color="var(--color-neutrals-black)" /> : <>&nbsp;</>}
    </ScoutHeroSection>
  )
}
