import React from 'react'
import classNames from 'classnames'
import Ski from './ski.png'
import SkiHelmet from './ski-helmet.png'
import SkiBoot from './ski-boot.png'
import GolfGlove from './golf-glove.png'
import GolfClub from './golf-club.png'
import GolfBag from './golf-bag.png'
import styles from './styles.css'

export const WinterSportsHeroImages: React.FC = () => {
  return (
    <>
      <HeroImage image={SkiHelmet} className={styles.skiHelmet} />
      <HeroImage image={SkiBoot} className={styles.skiBoot} />
      <HeroImage image={Ski} className={styles.ski} />
    </>
  )
}

export const GolfHeroImages: React.FC = () => {
  return (
    <>
      <HeroImage image={GolfGlove} className={styles.golfGlove} />
      <HeroImage image={GolfBag} className={styles.golfBag} />
      <HeroImage image={GolfClub} className={styles.golfClub} />
    </>
  )
}

const HeroImage: React.FC<{ image: string; className: string }> = ({ image, className }) => {
  return (
    <div
      className={classNames(styles.heroImage, className)}
      style={{ '--bg': `url('${image}')` } as React.CSSProperties}
    >
      &nbsp;
    </div>
  )
}
